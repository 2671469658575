<template>
    <!-- 二维码 -->
    <div class="out_box">
        <div class="qrcode">
            <div class="left">
                <div class="qrcode_img">
                    <img :src="top_img[0]" @click="getImg(0)" />
                </div>
                <div>微信公众号</div>
            </div>
            <div class="right">
                <div class="qrcode_img">
                    <img :src="top_img[1]" @click="getImg(1)" />
                </div>
                <div>官方客服</div>
            </div>
        </div>
        <div class="The_bottom">
            <div class="center_box">
                <div class="show_app1">
                    <div class="left" @click="down_app(0)">
                        客户端下载<br />
                        Android
                    </div>
                </div>
                <div class="show_app2">
                    <div class="right" @click="down_app(1)">
                        客户端下载<br />
                        iPhone
                    </div>
                </div>
                <div class="show_app3">
                    <div class="right" @click="down_app(2)">
                        司机端下载<br />
                        Android
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
    data() {
        return {
            top_img: [
                require('@/assets/app_code.png'),
                require('@/assets/service_code.png')
            ],
            app_img: [
                require('@/assets/Android.jpg'),
                require('@/assets/Apple_app.png'),
                require('@/assets/Android_car.png')
            ]
        }
    },

    methods: {
        getImg(index) {
            ImagePreview({
                images: this.top_img,
                showIndex: true,
                loop: false,
                startPosition: index
            })
        },
        getapp_img(index) {
            ImagePreview({
                images: this.app_img,
                showIndex: true,
                loop: false,
                startPosition: index
            })
        },
        down_app(num) {
            switch (num) {
                case 0:
                    window.location.href =
                        'https://app.szyuncheda.com/app/images/apk/member.apk'
                    break
                case 1:
                    window.location.href =
                        'https://apps.apple.com/us/app/%E8%BF%90%E8%BD%A6%E8%BE%BE/id6449725614'
                    break
                case 2:
                    window.location.href =
                        'https://app.szyuncheda.com/app/images/apk/driver.apk'
                    break
            }
        }
    }
}
</script>

<style lang="less" scoped>
.out_box {
    width: 100%;
    height: auto;
    background-color: #cc6600;
    padding-bottom: 1vh;
    position: relative;
    z-index: 999;
    .The_bottom {
        width: 90%;
        margin: 0 auto;
        padding-top: 1vh;
        padding-bottom: 1vh;
        border-top: 1px solid #d9d9d9;

        .center_box {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.7rem;
            text-align: center;
            color: rgba(17, 61, 83, 0.847);
            font-weight: bold;
            .show_app1 {
                .left {
                    width: 12vh;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin-right: 1vh;
                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                }
            }

            .show_app2 {
                .right {
                    width: 12vh;
                    border: 1px solid #ccc;
                    border-radius: 5px;

                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                }
            }
            .show_app3 {
                .right {
                    width: 12vh;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin-left: 1vh;
                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                }
            }
        }
    }
}
.qrcode {
    width: 100%;
    height: auto;
    color: #fff;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;
    justify-content: space-around;
    .left,
    .right {
        text-align: center;
    }
    .left {
        margin-left: 3vh;
    }
    .right {
        margin-right: 3vh;
    }
    .qrcode_img {
        width: 16vh;
        height: 16vh;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>