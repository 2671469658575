<template>
    <div class="box_out">
        <div class="box_copyright">
            <div class="copyright">
                <div class="left">
                    <p>版权所有 @ 2021-2023 深圳市运车达物流有限公司</p>
                    <p>
                        本站内容未经许可不得转载或挪用，如有发现将对其追究法律责任，谢谢合作！
                    </p>
                    <p>
                        24小时热线：400-8080-525&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司地址：深圳市龙岗区南湾街道丹竹头社区中兴路19号金盛大厦916
                    </p>
                    <p>全国统一热线：400-8080-525</p>
                    <p class="icp">
                        <!-- <a href="https://www.beian.gov.cn/portal/registerSystemInfo">粤icp备2023031061号</a> -->
						<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023031061号-1</a>
                    </p>
                    <!-- <p>友情链接：上海网站建设</p> -->
                </div>
                <div class="right">
                    <div class="one_i">
                        <div>
                            <img src="@/assets/service_code.png" />
                        </div>
                        <div>官方客服</div>
                    </div>
                    <div class="two_i">
                        <div>
                            <img src="@/assets/app_code.png" />
                        </div>
                        <div>微信公众号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="The_bottom">
            <div class="center_box">
                <div class="show_app1">
                    <div class="show1">
                        <img src="@/assets/Android.jpg" />
                        <p>扫描二维码下载手机端软件</p>
                    </div>
                    <div class="left">
                        客户端下载<br />
                        Android
                    </div>
                </div>
                <div class="show_app2">
                    <div class="show2">
                        <img src="@/assets/Apple_app.png" />
                        <p>扫描二维码下载手机端软件(苹果端)</p>
                    </div>
                    <div class="right">
                        客户端下载<br />
                        iPhone
                    </div>
                </div>
                <div class="show_app3">
                    <div class="show3">
                        <img src="@/assets/Android_car.png" />
                        <p>扫描二维码下载手机端软件(司机版)</p>
                    </div>
                    <div class="right">
                        司机端下载<br />
                        Android
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
export default {}
</script>
  
  <style lang="less" scoped>
.box_out {
    background-color: #cc6600;
    padding-bottom: 1vh;
    .The_bottom {
        width: 78%;
        margin: 0 auto;
        padding-top: 1vh;
        padding-bottom: 1vh;
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        .center_box {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.7rem;
            text-align: center;
            color: rgba(17, 61, 83, 0.847);
            font-weight: bold;
            .show_app1 {
                position: relative;
                cursor: pointer;
                .show1 {
                    position: absolute;
                    bottom: 6.6vh;
                    width: 14vh;
                    height: auto;
                    background-color: #fff;
                    padding: 0.4vh;
                    border-radius: 6px;
                    display: none;
                    img {
                        width: 100%;
                    }
                }
                .left {
                    width: 12vh;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin-left: 1vh;
                    margin-right: 1vh;
                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                }
            }
            .show_app1:hover {
                .left {
                    color: #fff;
                }
                .show1 {
                    display: block;
                }
            }
            .show_app2 {
                cursor: pointer;
                position: relative;
                .show2 {
                    position: absolute;
                    bottom: 6.6vh;
                    width: 14vh;
                    height: auto;
                    background-color: #fff;
                    padding: 0.4vh;
                    border-radius: 6px;
                    display: none;
                    img {
                        width: 100%;
                    }
                }
                .right {
                    width: 12vh;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin-left: 1vh;
                    margin-right: 1vh;
                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                }
            }
            .show_app2:hover {
                .right {
                    color: #fff;
                }
                .show2 {
                    display: block;
                }
            }
            .show_app3 {
                cursor: pointer;
                position: relative;
                .show3 {
                    position: absolute;
                    bottom: 6.6vh;
                    width: 14vh;
                    height: auto;
                    background-color: #fff;
                    padding: 0.4vh;
                    border-radius: 6px;
                    display: none;
                    img {
                        width: 100%;
                    }
                }
                .right {
                    width: 12vh;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin-left: 1vh;
                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                }
            }
            .show_app3:hover {
                .right {
                    color: #fff;
                }
                .show3 {
                    display: block;
                }
            }
        }
    }
}
.box_copyright {
    width: 100%;
    padding-top: 4.3vh;
    padding-bottom: 1vh;
    background-color: #cc6600;
    color: #e3e3e3;
    display: flex;
    justify-content: center;
    .copyright {
        width: 80%;
        display: flex;
        justify-content: space-between;
        .left {
            font-size: 0.7rem;
            margin-left: 2vh;
            p {
                margin: 0;
                line-height: 3vh;
            }
            p:nth-child(4) {
                font-size: 1.8rem;
                margin-top: 2vh;
                margin-bottom: 2vh;
            }
            .icp {
                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
            // p:nth-child(5){
            //     font-size: 0.7rem;
            //     color: #666666;
            // }
        }
        .right {
            display: flex;
            margin-right: 20vh;
            .one_i {
            }
            .two_i {
                margin-left: 4vh;
            }
            div:nth-child(1) {
                width: 14vh;
                height: 14vh;
                margin-bottom: 1vh;
                img {
                    width: 100%;
                }
            }
            div:nth-child(2) {
                font-size: 0.8rem;
                text-align: center;
            }
        }
    }
}
</style>