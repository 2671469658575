import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import AppIndex from '../views/app/index.vue'

Vue.use(VueRouter)

// 获取原型对象上的replace函数
const originalreplace = VueRouter.prototype.replace
// 修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
  return originalreplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: 'Home',
    children: [
      { path: '/Home', component: () => import('@/views/home/home.vue') },
      { path: '/Flow', component: () => import('@/views/consign/flow.vue') },
      { path: '/Subject', component: () => import('@/views/consign/subject.vue') },
      { path: '/Shipment', component: () => import('@/views/consign/shipment.vue') },
      { path: '/down', component: () => import('@/views/consign/down.vue') },
      { path: '/Know', component: () => import('@/views/consign/know.vue') },
      { path: '/Phone', component: () => import('@/views/consign/phone.vue') },
    ]
  },
  // app router
  {
    path: '/App',
    name: 'App',
    component: AppIndex
  },
  {
    path: '/App_line',
    component: () => import('@/views/app/to/app_line.vue')
  },
  {
    path: '/App_sense',
    component: () => import('@/views/app/to/app_sense.vue')
  },{
    path: '/App_phone',
    component: () => import('@/views/app/to/app_phone.vue')
  },{
    path: '/App_app',
    component: () => import('@/views/app/to/app_app.vue')
  },{
    path: '/App_Detailed',
    name: '/App_Detailed',
    component: () => import('@/views/app/to/app_Detailed.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router
