import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueAnimateNumber from 'vue-animate-number'

import scroll from 'vue-seamless-scroll'

import axios from 'axios'
import VueAxios from "vue-axios";
 
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.use(scroll)

Vue.use(VueAnimateNumber)

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
