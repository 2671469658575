let slect_one = [{
    value: '轿车',
    text: '轿车'
  }, {
    value: 'MPV',
    text: 'MPV'
  }, {
    value: 'SUV',
    text: 'SUV'
  }, {
    value: '跑车',
    text: '跑车'
  }, {
    value: '面包车',
    text: '面包车'
  },{
    value: '皮卡',
    text: '皮卡'
  },{
    value: '其他',
    text: '其他'
  }]
  
let Options = [
    {
    value: '安徽',
    text: 'A安徽',
    children: [{
      value: '合肥',
      text: '合肥'
    },{
        value: '阜阳',
        text: '阜阳'
    },{
        value: '芜湖',
        text: '芜湖'
    },{
        value: '蚌埠',
        text: '蚌埠'
    },{
        value: '淮南',
        text: '淮南'
    },{
        value: '马鞍山',
        text: '马鞍山'
    },{
        value: '淮北',
        text: '淮北'
    },{
        value: '铜陵',
        text: '铜陵'
    },{
        value: '安庆',
        text: '安庆'
    },{
        value: '黄山',
        text: '黄山'
    },{
        value: '滁州',
        text: '滁州'
    },{
        value: '宿州',
        text: '宿州'
    },{
        value: '巢湖',
        text: '巢湖'
    },{
        value: '六安',
        text: '六安'
    },{
        value: '亳州',
        text: '亳州'
    },{
        value: '池州',
        text: '池州'
    },{
        value: '宣城',
        text: '宣城'
    },]
  }, 

  {
    value: '北京',
    text: 'B北京',
    children: [{
      value: '北京',
      text: '北京'
    }]
  },
  {
    value: '重庆',
    text: 'C重庆',
    children: [{
      value: '重庆',
      text: '重庆'
    }]
  },
  {
    value: '福建',
    text: 'F福建',
    children: [{
      value: '福州',
      text: '福州'
    }, {
      value: '厦门',
      text: '厦门'
    }, {
      value: '三明',
      text: '三明'
    }, {
        value: '莆田',
        text: '莆田'
      }, {
        value: '泉州',
        text: '泉州'
      }, {
        value: '漳州',
        text: '漳州'
      }, {
        value: '南平',
        text: '南平'
      }, {
        value: '龙岩',
        text: '龙岩'
      }, {
        value: '宁德',
        text: '宁德'
      }]
  },
  {
    value: '广西',
    text: 'G广西',
    children: [{
      value: '南宁',
      text: '南宁'
    }, {
      value: '百色',
      text: '百色'
    }, {
      value: '柳州',
      text: '柳州'
    }, {
      value: '桂林',
      text: '桂林'
    }, {
      value: '梧州',
      text: '梧州'
    }, {
      value: '北海',
      text: '北海'
    }, {
      value: '防城港',
      text: '防城港'
    }, {
      value: '钦州',
      text: '钦州'
    }, {
      value: '贵港',
      text: '贵港'
    }, {
      value: '玉林',
      text: '玉林'
    }, {
      value: '贺州',
      text: '贺州'
    }, {
      value: '河池',
      text: '河池'
    }, {
      value: '来宾',
      text: '来宾'
    }, {
      value: '崇左',
      text: '崇左'
    }]
  },
  {  
    value: '贵州',
    text: 'G贵州',
    children: [{
      value: '贵阳',
      text: '贵阳'
    }, {
      value: '六盘水',
      text: '六盘水'
    }, {
      value: '遵义',
      text: '遵义'
    }, {
      value: '安顺',
      text: '安顺'
    }, {
      value: '铜仁',
      text: '铜仁'
    }, {
      value: '毕节',
      text: '毕节'
    }, {
      value: '凯里',
      text: '凯里'
    }, {
      value: '兴义',
      text: '兴义'
    }, {
      value: '都匀',
      text: '都匀'
    }]
  },
  {    
    value: '广东',
    text: 'G广东',
    children: [{
      value: '广州',
      text: '广州'
    }, {
      value: '清远',
      text: '清远'
    }, {
      value: '深圳',
      text: '深圳'
    },{
      value: '潮州',
      text: '潮州'
    }, {
      value: '肇庆',
      text: '肇庆'
    }, {
      value: '揭阳',
      text: '揭阳'
    },{
      value: '茂名',
      text: '茂名'
    }, {
      value: '佛山',
      text: '佛山'
    }, {
      value: '阳江',
      text: '阳江'
    },{
      value: '东莞',
      text: '东莞'
    }, {
      value: '云浮',
      text: '云浮'
    }, {
      value: '湛江',
      text: '湛江'
    },{
      value: '韶关',
      text: '韶关'
    }, {
      value: '江门',
      text: '江门'
    }, {
      value: '惠州',
      text: '惠州'
    }, {
      value: '珠海',
      text: '珠海'
    }, {
      value: '中山',
      text: '中山'
    }, {
      value: '汕尾',
      text: '汕尾'
    }, {
      value: '汕头',
      text: '汕头'
    }, {
      value: '梅州',
      text: '梅州'
    }, {
      value: '河源',
      text: '河源'
    }]
  },
  {
    value: '甘肃',
    text: 'G甘肃',
    children: [{
      value: '兰州',
      text: '兰州'
    }, {
      value: '酒泉',
      text: '酒泉'
    }, {
      value: '嘉峪关',
      text: '嘉峪关'
    }, {
      value: '庆阳',
      text: '庆阳'
    }, {
      value: '定西',
      text: '定西'
    }, {
      value: '陇南',
      text: '陇南'
    }, {
      value: '平凉',
      text: '平凉'
    }, {
      value: '白银',
      text: '白银'
    }, {
      value: '张掖',
      text: '张掖'
    }, {
      value: '金昌',
      text: '金昌'
    }, {
      value: '天水',
      text: '天水'
    }, {
      value: '武威',
      text: '武威'
    }]
  },
  {
    value: '海南',
    text: 'H海南',
    children: [{
      value: '定安县',
      text: '定安县'
    }, {
      value: '澄迈县',
      text: '澄迈县'
    }, {
      value: '三亚',
      text: '三亚'
    }, {
      value: '海口',
      text: '海口'
    }, {
      value: '五指山',
      text: '五指山'
    }, {
      value: '万宁',
      text: '万宁'
    }, {
      value: '儋州',
      text: '儋州'
    }, {
      value: '东方',
      text: '东方'
    }, {
      value: '文昌',
      text: '文昌'
    }, {
      value: '琼海',
      text: '琼海'
    }]
  },
  {  
    value: '湖北',
    text: 'H湖北',
    children: [{
      value: '恩施',
      text: '恩施'
    }, {
      value: '潜江',
      text: '潜江'
    }, {
      value: '天门',
      text: '天门'
    }, {
      value: '黄冈',
      text: '黄冈'
    }, {
      value: '仙桃',
      text: '仙桃'
    }, {
      value: '随州',
      text: '随州'
    }, {
      value: '咸宁',
      text: '咸宁'
    }, {
      value: '宜昌',
      text: '宜昌'
    }, {
      value: '孝感',
      text: '孝感'
    }, {
      value: '十堰',
      text: '十堰'
    }, {
      value: '鄂州',
      text: '鄂州'
    }, {
      value: '襄阳',
      text: '襄阳'
    }, {
      value: '黄石',
      text: '黄石'
    }, {
      value: '荆门',
      text: '荆门'
    }, {
      value: '荆州',
      text: '荆州'
    }, {
      value: '武汉',
      text: '武汉'
    }]
  },
  {
    value: '黑龙江',
    text: 'H黑龙江',
    children: [{
      value: '哈尔滨',
      text: '哈尔滨'
    }, {
      value: '齐齐哈尔',
      text: '齐齐哈尔'
    }, {
      value: '鹤岗',
      text: '鹤岗'
    }, {
      value: '双鸭山',
      text: '双鸭山'
    }, {
      value: '鸡西',
      text: '鸡西'
    }, {
      value: '伊春',
      text: '伊春'
    }, {
      value: '佳木斯',
      text: '佳木斯'
    }, {
      value: '牡丹江',
      text: '牡丹江'
    }, {
      value: '大庆',
      text: '大庆'
    }]
  },
  {
    value: '河南',
    text: 'H河南',
    children: [{
      value: '驻马店',
      text: '驻马店'
    }, {
      value: '周口',
      text: '周口'
    }, {
      value: '商丘',
      text: '商丘'
    }, {
      value: '信阳',
      text: '信阳'
    }, {
      value: '南阳',
      text: '南阳'
    }, {
      value: '漯河',
      text: '漯河'
    }, {
      value: '焦作',
      text: '焦作'
    }, {
      value: '三门峡',
      text: '三门峡'
    }, {
      value: '许昌',
      text: '许昌'
    }, {
      value: '安阳',
      text: '安阳'
    }, {
      value: '新乡',
      text: '新乡'
    }, {
      value: '平顶山',
      text: '平顶山'
    }, {
      value: '开封',
      text: '开封'
    }, {
      value: '濮阳',
      text: '濮阳'
    }, {
      value: '洛阳',
      text: '洛阳'
    }, {
      value: '郑州',
      text: '郑州'
    }, {
      value: '鹤壁',
      text: '鹤壁'
    }]
  },
  {
    value: '河北',
    text: 'H河北',
    children: [{
      value: '石家庄',
      text: '石家庄'
    }, {
      value: '唐山',
      text: '唐山'
    }, {
      value: '秦皇岛',
      text: '秦皇岛'
    }, {
      value: '邯郸',
      text: '邯郸'
    }, {
      value: '邢台',
      text: '邢台'
    }, {
      value: '张家口',
      text: '张家口'
    }, {
      value: '保定',
      text: '保定'
    }, {
      value: '承德',
      text: '承德'
    }, {
      value: '沧州',
      text: '沧州'
    }, {
      value: '廊坊',
      text: '廊坊'
    }, {
      value: '衡水',
      text: '衡水'
    }]
  },
  {    
    value: '湖南',
    text: 'H湖南',
    children: [{
      value: '张家界',
      text: '张家界'
    }, {
      value: '益阳',
      text: '益阳'
    }, {
      value: '郴州',
      text: '郴州'
    }, {
      value: '娄底',
      text: '娄底'
    }, {
      value: '怀化',
      text: '怀化'
    }, {
      value: '永州',
      text: '永州'
    }, {
      value: '长沙',
      text: '长沙'
    }, {
      value: '衡阳',
      text: '衡阳'
    }, {
      value: '株洲',
      text: '株洲'
    }, {
      value: '岳阳',
      text: '岳阳'
    }, {
      value: '常德',
      text: '常德'
    }, {
      value: '湘潭',
      text: '湘潭'
    }, {
      value: '邵阳',
      text: '邵阳'
    }]
  },
  {
    value: '江苏',
    text: 'J江苏',
    children: [{
      value: '无锡',
      text: '无锡'
    }, {
      value: '苏州',
      text: '苏州'
    }, {
      value: '南通',
      text: '南通'
    }, {
      value: '常州',
      text: '常州'
    }, {
      value: '常熟',
      text: '常熟'
    }, {
      value: '昆山',
      text: '昆山'
    }, {
      value: '江阴',
      text: '江阴'
    }, {
      value: '宿迁',
      text: '宿迁'
    }, {
      value: '南京',
      text: '南京'
    }, {
      value: '连云港',
      text: '连云港'
    }, {
      value: '徐州',
      text: '徐州'
    }, {
      value: '淮安',
      text: '淮安'
    }, {
      value: '盐城',
      text: '盐城'
    }, {
      value: '扬州',
      text: '扬州'
    }, {
      value: '泰州',
      text: '泰州'
    }, {
      value: '镇江',
      text: '镇江'
    }]
  },
  {   
    value: '吉林',
    text: 'J吉林',
    children: [{
      value: '延边',
      text: '延边'
    }, {
      value: '白城',
      text: '白城'
    }, {
      value: '辽源',
      text: '辽源'
    }, {
      value: '长春',
      text: '长春'
    }, {
      value: '吉林',
      text: '吉林'
    }, {
      value: '松原',
      text: '松原'
    }, {
      value: '四平',
      text: '四平'
    }, {
      value: '白山',
      text: '白山'
    }]
  },
  { 
    value: '江西',
    text: 'J江西',
    children: [{
      value: '上饶',
      text: '上饶'
    }, {
      value: '抚州',
      text: '抚州'
    }, {
      value: '南昌',
      text: '南昌'
    }, {
      value: '宜春',
      text: '宜春'
    }, {
      value: '九江',
      text: '九江'
    }, {
      value: '景德镇',
      text: '景德镇'
    }, {
      value: '吉安',
      text: '吉安'
    }, {
      value: '萍乡',
      text: '萍乡'
    }, {
      value: '新余',
      text: '新余'
    }, {
      value: '鹰潭',
      text: '鹰潭'
    }, {
      value: '赣州',
      text: '赣州'
    }]
  },
  {   
    value: '辽宁',
    text: 'L辽宁',
    children: [{
      value: '抚顺',
      text: '抚顺'
    }, {
      value: '阜新',
      text: '阜新'
    }, {
      value: '铁岭',
      text: '铁岭'
    }, {
      value: '朝阳',
      text: '朝阳'
    }, {
      value: '丹东',
      text: '丹东'
    }, {
      value: '辽阳',
      text: '辽阳'
    }, {
      value: '盘锦',
      text: '盘锦'
    }, {
      value: '鞍山',
      text: '鞍山'
    }, {
      value: '营口',
      text: '营口'
    }, {
      value: '本溪',
      text: '本溪'
    }, {
      value: '葫芦岛',
      text: '葫芦岛'
    }, {
      value: '锦州',
      text: '锦州'
    }, {
      value: '沈阳',
      text: '沈阳'
    }, {
      value: '大连',
      text: '大连'
    }]
  },
  { 
    value: '内蒙古',
    text: 'N内蒙古',
    children: [{
      value: '呼伦贝尔',
      text: '呼伦贝尔'
    }, {
      value: '乌海',
      text: '乌海'
    }, {
      value: '阿拉善盟',
      text: '阿拉善盟'
    }, {
      value: '巴彦淖尔',
      text: '巴彦淖尔'
    }, {
      value: '包头',
      text: '包头'
    }, {
      value: '呼和浩特',
      text: '呼和浩特'
    }, {
      value: '鄂尔多斯',
      text: '鄂尔多斯'
    }, {
      value: '通辽',
      text: '通辽'
    }, {
      value: '锡林郭勒盟',
      text: '锡林郭勒盟'
    }, {
      value: '乌兰察布',
      text: '乌兰察布'
    }, {
      value: '兴安盟',
      text: '兴安盟'
    }, {
      value: '赤峰',
      text: '赤峰'
    }]
  },
  {
    value: '宁夏',
    text: 'N宁夏',
    children: [{
      value: '银川',
      text: '银川'
    }, {
      value: '固原',
      text: '固原'
    }, {
      value: '石嘴山',
      text: '石嘴山'
    }, {
      value: '中卫',
      text: '中卫'
    }, {
      value: '吴忠',
      text: '吴忠'
    }]
  },
  {
    value: '青海',
    text: 'Q青海',
    children: [{
      value: '西宁',
      text: '西宁'
    }, {
      value: '海东',
      text: '海东'
    }, {
      value: '格尔木',
      text: '格尔木'
    }]
  },
  {    
    value: '四川',
    text: 'S四川',
    children: [{
      value: '达州',
      text: '达州'
    }, {
      value: '巴中',
      text: '巴中'
    }, {
      value: '资阳',
      text: '资阳'
    }, {
      value: '雅安',
      text: '雅安'
    }, {
      value: '眉山',
      text: '眉山'
    }, {
      value: '南充',
      text: '南充'
    }, {
      value: '广安',
      text: '广安'
    }, {
      value: '宜宾',
      text: '宜宾'
    }, {
      value: '乐山',
      text: '乐山'
    }, {
      value: '绵阳',
      text: '绵阳'
    }, {
      value: '德阳',
      text: '德阳'
    }, {
      value: '遂宁',
      text: '遂宁'
    }, {
      value: '内江',
      text: '内江'
    }, {
      value: '广元',
      text: '广元'
    }, {
      value: '泸州',
      text: '泸州'
    }, {
      value: '攀枝花',
      text: '攀枝花'
    }, {
      value: '自贡',
      text: '自贡'
    }, {
      value: '成都',
      text: '成都'
    }]
  },
  {
    value: '陕西',
    text: 'S陕西',
    children: [{
      value: '商洛',
      text: '商洛'
    }, {
      value: '安康',
      text: '安康'
    }, {
      value: '宝鸡',
      text: '宝鸡'
    }, {
      value: '咸阳',
      text: '咸阳'
    }, {
      value: '榆林',
      text: '榆林'
    }, {
      value: '延安',
      text: '延安'
    }, {
      value: '西安',
      text: '西安'
    }, {
      value: '汉中',
      text: '汉中'
    }, {
      value: '渭南',
      text: '渭南'
    }, {
      value: '铜川',
      text: '铜川'
    }]
  },
  {
    value: '上海',
    text: 'S上海',
    children: [{
      value: '上海',
      text: '上海'
    }]
  },
  {
    value: '山东',
    text: 'S山东',
    children: [{
      value: '临沂',
      text: '临沂'
    }, {
      value: '德州',
      text: '德州'
    }, {
      value: '莱芜',
      text: '莱芜'
    }, {
      value: '潍坊',
      text: '潍坊'
    }, {
      value: '滨州',
      text: '滨州'
    }, {
      value: '枣庄',
      text: '枣庄'
    }, {
      value: '泰安',
      text: '泰安'
    }, {
      value: '济宁',
      text: '济宁'
    }, {
      value: '淄博',
      text: '淄博'
    }, {
      value: '东营',
      text: '东营'
    }, {
      value: '威海',
      text: '威海'
    }, {
      value: '烟台',
      text: '烟台'
    }, {
      value: '聊城',
      text: '聊城'
    }, {
      value: '日照',
      text: '日照'
    }, {
      value: '菏泽',
      text: '菏泽'
    }, {
      value: '青岛',
      text: '青岛'
    }, {
      value: '济南',
      text: '济南'
    }]
  },
  {  
    value: '山西',
    text: 'S山西',
    children: [{
      value: '太原',
      text: '太原'
    }, {
      value: '大同',
      text: '大同'
    }, {
      value: '忻州',
      text: '忻州'
    }, {
      value: '吕梁',
      text: '吕梁'
    }, {
      value: '运城',
      text: '运城'
    }, {
      value: '临汾',
      text: '临汾'
    }, {
      value: '晋中',
      text: '晋中'
    }, {
      value: '长治',
      text: '长治'
    }, {
      value: '朔州',
      text: '朔州'
    }, {
      value: '阳泉',
      text: '阳泉'
    }, {
      value: '晋城',
      text: '晋城'
    }]
  },
  {
    value: '天津',
    text: 'T天津',
    children: [{
      value: '天津',
      text: '天津'
    }]
  },
  { 
    value: '西藏',
    text: 'X西藏',
    children: [{
      value: '拉萨',
      text: '拉萨'
    }, {
      value: '山南',
      text: '山南'
    }, {
      value: '日喀则',
      text: '日喀则'
    }, {
      value: '阿里',
      text: '阿里'
    }, {
      value: '林芝',
      text: '林芝'
    }, {
      value: '昌都',
      text: '昌都'
    }, {
      value: '那曲',
      text: '那曲'
    }]
  },
  {   
    value: '新疆',
    text: 'X新疆',
    children: [{
      value: '乌鲁木齐',
      text: '乌鲁木齐'
    }, {
      value: '伊宁',
      text: '伊宁'
    }, {
      value: '昌吉',
      text: '昌吉'
    }, {
      value: '博乐',
      text: '博乐'
    }, {
      value: '北屯',
      text: '北屯'
    }, {
      value: '塔城',
      text: '塔城'
    }, {
      value: '轮台',
      text: '轮台'
    }, {
      value: '库车',
      text: '库车'
    }, {
      value: '阿克苏',
      text: '阿克苏'
    }, {
      value: '库尔勒',
      text: '库尔勒'
    }, {
      value: '五家渠',
      text: '五家渠'
    }, {
      value: '图木舒克',
      text: '图木舒克'
    }, {
      value: '克拉玛依',
      text: '克拉玛依'
    }, {
      value: '吐鲁番',
      text: '吐鲁番'
    }, {
      value: '石河子',
      text: '石河子'
    }, {
      value: '哈密',
      text: '哈密'
    }, {
      value: '阿拉尔',
      text: '阿拉尔'
    }, {
      value: '喀什',
      text: '喀什'
    }, {
      value: '和田',
      text: '和田'
    }]
  },
  {
    value: '云南',
    text: 'Y云南',
    children: [{
      value: '昆明',
      text: '昆明'
    }, {
      value: '曲靖',
      text: '曲靖'
    }, {
      value: '普洱',
      text: '普洱'
    }, {
      value: '临沧',
      text: '临沧'
    }, {
      value: '红河',
      text: '红河'
    }, {
      value: '文山',
      text: '文山'
    }, {
      value: '西双版纳',
      text: '西双版纳'
    }, {
      value: '丽江',
      text: '丽江'
    }, {
      value: '保山',
      text: '保山'
    }, {
      value: '昭通',
      text: '昭通'
    }, {
      value: '玉溪',
      text: '玉溪'
    }]
  },
  {
    value: '浙江',
    text: 'Z浙江',
    children: [{
      value: '杭州',
      text: '杭州'
    }, {
      value: '嘉兴',
      text: '嘉兴'
    }, {
      value: '台州',
      text: '台州'
    }, {
      value: '金华',
      text: '金华'
    }, {
      value: '舟山',
      text: '舟山'
    }, {
      value: '丽水',
      text: '丽水'
    }, {
      value: '义乌',
      text: '义乌'
    }, {
      value: '衢州',
      text: '衢州'
    }, {
      value: '绍兴',
      text: '绍兴'
    }, {
      value: '湖州',
      text: '湖州'
    }, {
      value: '温州',
      text: '温州'
    }, {
      value: '宁波',
      text: '宁波'
    }]
  }
  

]

export {
    slect_one,
    Options
}